
import accountSignup from 'mixins/accountSignup';
import analytics from 'mixins/analytics';
import ipCountry from 'mixins/ipCountry';
import lazyLoadScript from 'mixins/lazyLoadScript';
import hideVendorWidgets from 'mixins/hideVendorWidgets';

export default {
  mixins: [
    ipCountry,
    accountSignup,
    analytics,
    lazyLoadScript,
    hideVendorWidgets,
  ],
  head() {
    return {
      script: [{ hid: 'airgap-tag', src: '' }],
    };
  },
  middleware: ['region', 'setStoreData'],
};
