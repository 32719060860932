
import deputyCustomer from 'mixins/deputyCustomer';
import accountSignup from 'mixins/accountSignup';
import analytics from 'mixins/analytics';
import MenuNavigation from '../components/menus/menu-navigation';
import MenuFooter from '../components/menus/menu-footer';
import ipCountry from 'mixins/ipCountry';
import modal from 'mixins/modal';
import generalExitPopUp from 'mixins/generalExitPopUp';
import { mapGetters, mapState } from 'vuex';
import throttle from 'lodash/throttle';
import { ModalsContainer } from 'vue-final-modal';
import debounce from 'lodash/debounce';
import freemiumMods from '~/utils/freemiumModifications';
import lazyLoadScript from 'mixins/lazyLoadScript';
import hideVendorWidgets from 'mixins/hideVendorWidgets';

export default {
  components: {
    MenuNavigation,
    MenuFooter,
    ModalsContainer,
    MarketoForm: () => import('./../components/marketo-form'),
    PopUpNotification: () => import('./../components/pop-up-notification'),
    Drift: () => import('./../components/drift'),
  },
  mixins: [
    ipCountry,
    deputyCustomer,
    accountSignup,
    analytics,
    modal,
    generalExitPopUp,
    lazyLoadScript,
    hideVendorWidgets,
  ],
  middleware: ['region', 'setStoreData'],
  data() {
    return {
      isScrolled: false,
      isUserActive: false,
      mainContainerClass: null,
    };
  },
  computed: {
    ...mapGetters({
      hideHeader: 'menu/isMenuHidden',
    }),
    ...mapState({
      showFreemium: state => state.showFreemium,
      websiteRegion: state => state.region,
      modalData: state => state.modal.modalData,
      blogModalData: state => state.modal.blogModalData,
    }),
  },
  created() {
    if (this.websiteRegion === 'us') {
      this.mainContainerClass = 'main-blur';
    }
  },
  beforeMount() {
    // TODO might be good to start thinking of refactoring this part now : WWW-785
    // if we detect the gclid parameter in the url we should load immediately segment
    if (document.location.href.includes('gclid')) {
      this.$store.commit('setLoadAssets', 'segment');
    }
    // Facebook, LinkedIn ads are constructed with UTM
    // if we detect any we can load the script immediately to catch it as soon as possible
    if (document.location.href.includes('utm')) {
      this.$store.commit('setLoadAssets', 'segment,facebook,linkedin');
    }
    if (document.location.href.includes('book=consult')) {
      // load marketo script on page load
      this.$store.commit(
        'setLoadAssets',
        'segment,munchkin,marketo,chilipiper'
      );
    }
    if (document.location.href.includes('signup=open')) {
      // load recaptcha script on page load
      this.$store.commit(
        'setLoadAssets',
        'segment,facebook,linkedin,recaptcha'
      );
    }
    if (this.showFreemium) {
      let counter = 0;
      const checkIfPageLoaded = setInterval(() => {
        // up to 1s 200x5ms then we clear the blur if still not resolved
        if (counter <= 200) {
          if (window.isPageLoaded) {
            freemiumMods();
            this.removeBlur();
            clearInterval(checkIfPageLoaded);
          }
          counter++;
        } else {
          freemiumMods();
          this.removeBlur();
          clearInterval(checkIfPageLoaded);
        }
      }, 5);
    } else {
      this.removeBlur();
    }
  },
  mounted() {
    const headerElement = document.getElementById('header');
    new ResizeObserver(debounce(this.updateHeaderOffset, 50)).observe(
      headerElement
    );
    window.addEventListener('scroll', throttle(this.handleScroll, 500));
    this.$nextTick(() => {
      this.removeBlur();
      if (
        Object.keys(this.modalData).length !== 0 ||
        Object.keys(this.blogModalData).length !== 0
      ) {
        this.activeExitPopUpModal();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    clicked() {
      this.$root.$emit('click-outside', true);
      this.loadAssets();
    },
    handleScroll() {
      this.isScrolled = window.scrollY !== 0;
      this.isUserActive = true;
      this.loadAssets();
    },
    mouseEnter() {
      this.isUserActive = true;
      this.loadAssets();
    },
    removeBlur() {
      this.mainContainerClass = null;
      const el = this.$refs.mainContainer;
      if (el) {
        el.classList.remove('main-blur');
      }
    },
    updateHeaderOffset() {
      this.$store.dispatch('menu/setHeaderOffset');
    },
    loadAssets() {
      if (process.client && this.$store.state.loadAssets !== 'all') {
        this.$store.commit('setLoadAssets', 'all');
      }
    },
  },
};
