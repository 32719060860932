
export default {
  head() {
    return {
      bodyAttrs: {
        class: 'only-logo-lp',
      },
    };
  },
};
