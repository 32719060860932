
import CmsError from '../components/cms-error';

export default {
  components: { CmsError },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
};
